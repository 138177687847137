<template>
  <section class="hero">
    <div class="container">
      <h1 v-html="content.hero.title"></h1>
      <a :href="content.hero.href" class="btn btn-primary" target="_blank" rel="noreferrer">{{content.hero.btn}}</a>
    </div>
  </section>
  <section class="competition">
    <div class="container">
      <h2>{{content.competition.title}}</h2>
      <div v-html="content.competition.txt"></div>
      <router-link class="btn btn-primary" :to="{name:'Page_270_'+language}">{{content.competition.btn}}</router-link>
    </div>
  </section>
  <section class="timing">
    <div class="container">
      <h2>{{content.timing.title}}</h2>
      <div v-html="content.timing.txt"></div>
    </div>
  </section>
  <section class="awards">
    <div class="container">
      <h3>{{content.awards.title}}</h3>
      <div v-html="content.awards.txt"></div>
      <div class="row mx-auto">
        <div class="col-sm-4">
          <div class="award first">
            <div class="price" v-html="content.awards.price1">
            </div>
            <div class="position">
              {{content.awards.position1}}
            </div>
            <div class="desc">
              {{content.awards.desc1}}
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="award second">
            <div class="price" v-html="content.awards.price2">
            </div>
            <div class="position">
              {{content.awards.position2}}
            </div>
            <div class="desc">
              {{content.awards.desc2}}
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="award third">
            <div class="price" v-html="content.awards.price3">
            </div>
            <div class="position">
              {{content.awards.position3}}
            </div>
            <div class="desc">
              {{content.awards.desc3}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="company">
    <div class="container">
      <img src="@/assets/img/loxon-logo.png" alt="Loxon logó"/>
      <div v-html="content.company.txt"></div>
      <router-link class="btn btn-secondary" :to="{name:'Page_274_'+language}">{{content.company.btn}}</router-link>
    </div>
  </section>
  <section class="slider">

  </section>
  <section class="slider">
    <div class="slideshow-container">
      <div class="slides fade">
        <img src="../assets/img/sliders/slider_1.jpg" alt=""/>
      </div>
      <div class="slides fade">
        <img src="../assets/img/sliders/slider_2.jpg" alt=""/>
      </div>
      <div class="slides fade">
        <img src="../assets/img/sliders/slider_3.jpg" alt=""/>
      </div>
      <div class="slides fade">
        <img src="../assets/img/sliders/slider_4.jpg" alt=""/>
      </div>
      <div class="slides fade">
        <img src="../assets/img/sliders/slider_5.jpg" alt=""/>
      </div>
      <a class="transparent-nav prev"></a>
      <a class="transparent-nav next"></a>
    </div>
    <div class="dot-holder">
      <span class="dot" data-n="1"></span>
      <span class="dot" data-n="2"></span>
      <span class="dot" data-n="3"></span>
      <span class="dot" data-n="4"></span>
      <span class="dot" data-n="5"></span>
    </div>
  </section>
  <section class="participants">
    <div class="container">
      <h3>{{content.participants.title}}</h3>
      <Participants :initData="initData"></Participants>
      <div class="text text-center">
        <div v-html="content.participants.txt"></div>
        <router-link class="btn btn-secondary" :to="{name:'Page_276_'+language}">{{content.participants.btn}}</router-link>
      </div>
    </div>
  </section>
</template>

<script>

import Participants from "@/components/Participants.vue";

export default {
  name: 'MainPage',
  components: {Participants},
  props:['initData','contents','pageId'],
  data(){
    return{
      slideIndex : 0
    }
  },
  mounted(){
    this.listenerInit()
    this.changeSlides()
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  },
  methods:{
    listenerInit(){
      let prev = document.querySelector('.slider .prev')
      let next = document.querySelector('.slider .next')
      prev.addEventListener('click', this.minusSlides.bind(this))
      next.addEventListener('click', this.plusSlides.bind(this))
      let dots = document.querySelectorAll('.slider .dot');
      for(let i=0; i<dots.length; i++){
        dots[i].addEventListener('click', this.currentSlide.bind(this))
      }
    },
    plusSlides() {
      this.showSlides(this.slideIndex += 1)
    },
    minusSlides() {
      this.showSlides(this.slideIndex -= 1)
    },
    currentSlide(e) {
      let n = e.target.dataset.n;
      this.showSlides(this.slideIndex = n)
    },
    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("slides")
      let dots = document.getElementsByClassName("dot")
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "")
      }
      slides[this.slideIndex-1].style.display = "contents"
      dots[this.slideIndex-1].className += " active"
    },

    changeSlides() {
      let i;
      let slides = document.getElementsByClassName("slides")
      let dots = document.getElementsByClassName("dot")
      if(!dots){
        return
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none"
      }
      this.slideIndex++;
      if (this.slideIndex > slides.length) {this.slideIndex = 1}
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "")
      }
      slides[this.slideIndex-1].style.display = "contents"
      dots[this.slideIndex-1].className += " active"
      setTimeout(this.changeSlides.bind(this), 4000)
    }
  }
}
</script>
<style scoped>
section{
  font-family: 'TitilliumWeb-Light', sans-serif;
}
.hero{
  background: url("@/assets/img/hero-bgr.png") center center no-repeat;
  background-size: cover;
  padding: 5rem 0 4rem 0;
}
.hero h1{
  font-family: 'TitilliumWeb-Light', sans-serif;
  color: var(--white);
  font-size: 4.8rem;
  line-height: 4.4rem;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}
.hero h1 :deep(span){
  font-family: 'TitilliumWeb-SemiBold', sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  text-transform: uppercase;
}
.competition{
  width: 100%;
  background-color: var(--loxon-blue);
  padding: 4rem 0;
  color: var(--white);
}
.competition h2{
  padding-bottom: 1.2rem;
}
.timing{
  padding: 4rem 0 0 0;
  margin-bottom: 4rem;
}
.timing h2{
  color: var(--loxon-blue);
}
.timing :deep(p:nth-child(1)){
  max-width: 510px;
  font-size: 1rem;
  line-height: 1.3rem;
}
.timing :deep(p){
  font-size: 2rem;
  line-height: 2rem;
}
.timing :deep(p strong){
  font-family: 'TitilliumWeb-Light', sans-serif;
  color: var(--loxon-blue);
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.awards{
  background-color: var(--loxon-blue);
  padding: 4rem 0 12rem 0;
  color: var(--white);
}
.awards .row{
  max-width: 1060px;
  margin-top: 2.5rem;
}
.awards .row .col-sm-4{
  padding: 0 40px;
}
.award .price{
  padding: 4rem 0 4rem 0;
  font-family: 'TitilliumWeb-Light', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.award .position{
  text-transform: uppercase;
  line-height: 1.2rem;
  margin-bottom: 0.4rem;
}
.award .desc{
  font-family: 'TitilliumWeb-SemiBold', sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
}
.award .price :deep(span){
  font-size: 6rem;
  line-height: 6rem;
}
.award.first{
  background: url("@/assets/img/award_1.png") center top no-repeat;
  background-size: 100%;
}
.award.second{
  background: url("@/assets/img/award_2.png") center top no-repeat;
  background-size: 100%;
}
.award.third{
  background: url("@/assets/img/award_3.png") center top no-repeat;
  background-size: 100%;
}
.company{
  max-width: 1280px;
  margin: 0 auto;
  background-image: url("@/assets/img/company-bgr.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 45%;
  padding: 4.5rem 0;
}
.company img{
  margin-bottom: 3rem;
}
.company :deep(p){
  max-width: 435px;
  margin-bottom: 1.5rem;
}
.participants{
  background-color: var(--loxon-orange);
  padding: 4rem 0;
}
.participants h3{
  color: var(--white);
  margin-bottom: 2.75rem;
}
.participants .text{
  margin-top: 2.5rem;
}
/*--------------------
        SLIDER
---------------------*/
.slider{
  position: relative;
}
.slider .slideshow-container {
  position: relative;
  margin: auto;
}
.slider .dot-holder {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 38px;
  z-index: 4;
}

.slider .slides {
  display: none;
}
.slider .slides img{
  width: 100%;
}
/* Next & previous buttons */
.slider .prev,.slider .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.slider .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.slider .prev:hover,.slider .next:hover {
  background-color: rgba(0,0,0,0.8);
}
.slider  .dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 8px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.slider .active,#company .dot:hover {
  background-color: var(--loxon-orange);
}
.slider .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
.slider .slides{position:relative;list-style:none;overflow:hidden;width:100%;padding:0;margin:0;}
.slider .transparent-nav{
  z-index:3;
  position:absolute;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  top:0;
  left:0;
  display:block;
  background:#fff;
  opacity:0;
  filter:alpha(opacity=1);
  width:48%;
  text-indent:-9999px;
  overflow:hidden;
  height:100%;
}
.slider .transparent-nav.next{
  left:auto;
  right:0;
}
.slider .transparent-nav:focus{
  outline:none;
}



@media (max-width: 1030px) {

}
@media (max-width: 768px){
  .company{
    background-position: center 78%;
    background-size: 100%;
  }
  .company .btn{
    margin-top: 26rem;
  }
}
@media screen and (max-width: 576px) {
  .hero h1{
    font-size: 2rem;
    line-height: 2rem;
  }
  .company{
    background-position: center 78%;
  }
  .company .btn{
    margin-top: 20rem;
  }
}
</style>
